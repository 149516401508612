import Anxiety from '../../assets/anxiety.svg'
import Cardio from '../../assets/cardio.svg'
import CookingImage from '../../assets/cooking.svg'
import DepressionAlt from '../../assets/depression-alt.svg'
import FitnessImage from '../../assets/fitness.svg'
import GroceriesImage from '../../assets/groceries.svg'
import SleepImage from '../../assets/sleep.svg'
import Waves from '../../assets/wave.svg'
import WeightLoss from '../../assets/weight-loss.svg'

export const principles = {
  Anxiety: Anxiety,
  Cardio: Cardio,
  Cooking: CookingImage,
  Depression: DepressionAlt,
  Groceries: GroceriesImage,
  Lifting: FitnessImage,
  Sleep: SleepImage,
  'Urge surfing': Waves,
  'Weight loss': WeightLoss,
}

export type PrincipleMap = typeof principles
export type Principles = keyof PrincipleMap

export const principlesPermalinks: Record<string, Principles> = {
  anxiety: 'Anxiety',
  cardio: 'Cardio',
  cooking: 'Cooking',
  depression: 'Depression',
  groceries: 'Groceries',
  lifting: 'Lifting',
  sleep: 'Sleep',
  'urge-surfing': 'Urge surfing',
  'weight-loss': 'Weight loss',
}

export const principlesPermalinksReversed = {} as Record<Principles, string>
Object.keys(principlesPermalinks).forEach((key) => {
  const value = principlesPermalinks[key]
  principlesPermalinksReversed[value] = key
})

export const principlesSeo: Record<Principles, string> = {
  Anxiety:
    "In this guide, you'll learn everything you need to know to tackle your anxiety. You got this!",
  Cardio:
    "In this guide, you'll learn everything you need to know to start a cardio. No personal trainers required!",
  Cooking:
    "In this guide, you'll learn how to quickly prepare the key food groups of weight loss: lean protein and vegetables.",
  Depression:
    "In this guide, you'll learn everything you need to know to tackle your depression. You got this!",
  Groceries:
    "In this guide, you'll learn what foods in the grocery store are best for weight loss.",
  Lifting:
    "In this guide, you'll learn everything you need to know to start a lifting routine. No personal trainers required!",
  Sleep:
    "In this guide, you'll learn everything you need to know to optimize your sleep. Simple steps, powerful results!",
  'Urge surfing':
    "In this guide, you'll learn everything you need to know to master the supremely useful skill of urge surfing.",
  'Weight loss':
    "In this set of modules, you'll learn everything you need to know to lose weight, from self-compassion to exercise.",
}
