import { css } from '@emotion/react'
import Link from 'next/link'
import { colors, md, xs } from '../../theme/theme'

export const Navbar = () => {
  const navbar = css`
    background-color: white;
    border-bottom: 1px solid ${colors.lightgrey};
    height: 70px;
    left: 0;
    position: fixed;
    top: 0;

    a {
      text-decoration: none !important;
    }

    @media (max-width: ${md}px) {
      justify-content: center;
    }

    button {
      font-size: 0.9rem;
      height: 37px;
      margin: 0 10px;
      padding: 10px 15px;
      @media (max-width: ${xs}px) {
        font-size: 0.7rem;
      }
    }

    #title {
      font-size: 1.2rem;
      font-weight: 700;
      margin-left: 20px;
      margin-right: 15px;
      @media (max-width: ${xs}px) {
        font-size: 1rem !important;
      }
    }
  `

  return (
    <div css={navbar} className={'fr w100 wrap z1'}>
      <Link href="/" passHref>
        <span
          id="title"
          css={css`
            font-size: 1.3rem !important;
            font-weight: 700 !important;
          `}
        >
          Clean Slate
        </span>
      </Link>
      <div className="expand fr">
        <Link href="/curriculum" passHref>
          <button type="button" className="blue semi-bold normal">
            Curriculum
          </button>
        </Link>

        <a
          css={css`
            height: 37px;
          `}
          href="https://app.cleanslate.sh"
          suppressHydrationWarning={true}
          type="button"
          className="pink semi-bold normal button"
        >
          App
        </a>
      </div>
    </div>
  )
}
