import { css } from '@emotion/react'
import Link from 'next/link'
import { isBrowser } from '../../helpers/isBrowser'
import { colors } from '../../theme/theme'

export const Footer: React.FC = () => {
  const footer = css`
    background-color: ${colors.black};
    color: ${colors.white};
    font-size: 0.8rem;
    height: 40px;
    margin-top: 100px;

    a {
      color: ${colors.white};
      margin: 0 5px;
      text-decoration: none;
    }
  `

  return (
    <div css={footer} className={'frc w100'}>
      <div>
        © {new Date().getFullYear()} Clean Slate <span className="ml5">|</span>{' '}
        <a
          onClick={() =>
            isBrowser() &&
            window.alert(
              `Glad you want to say hello! Email contact@cleanslate.sh and we'll try to get back to you in 24 hours.`
            )
          }
          href="mailto:contact@cleanslate.sh"
        >
          Contact
        </a>
        <span className="ml5">|</span>
        <Link href="/legal">Legal</Link>
      </div>
    </div>
  )
}
