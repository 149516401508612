import { type SerializedStyles, css } from '@emotion/react'
import { useRouter } from 'next/dist/client/router'
import Head from 'next/head'
import type React from 'react'
import { Footer } from './footer/Footer'
import { Navbar } from './navbar/Navbar'
import { getDescription, getTitle } from './seo'

type props = {
  children: React.ReactNode
  className?: string
  styles?: SerializedStyles
}
export const Layout: React.FC<props> = ({ children, className, styles }) => {
  // Get route
  const router = useRouter()
  const pathname = router.pathname

  return (
    <div>
      <Head>
        <title>{`${getTitle(pathname)} | Clean Slate`}</title>
        <meta name="description" content={getDescription(pathname)} />
      </Head>
      <Navbar />
      <div
        className="mt100"
        css={[
          styles,
          css`
            min-height: calc(100vh - (70px + 40px + 100px + 30px));
          `,
        ]}
      >
        {children}
      </div>
      <Footer />
    </div>
  )
}
