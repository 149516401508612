import { principlesPermalinks, principlesSeo } from './curriculum/Principles'

export const getTitle = (pathname: string) => {
  const manifest = {
    '': 'Home',
    curriculum: 'Curriculum',
    ...principlesPermalinks,
  } as Record<string, string>

  const permalink = pathname.replace('/', '').replace('/', '')
  const title = manifest[permalink]
  return title ? title : 'Not Found'
}

export const getDescription = (pathname: string) => {
  const manifest = {
    curriculum: `In this free curriculum, you'll learn how to lose weight quickly, sustainably, and with great kindness towards yourself`,
    ...principlesSeo,
  } as Record<string, string>

  const description =
    manifest[principlesPermalinks[pathname.replace('/', '').replace('/', '')]]

  return description
    ? description
    : 'Use Clean Slate to track your food without judgment. Designed for people who struggle with weight loss, binge eating, and perfectionism.'
}
