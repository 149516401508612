import { css } from '@emotion/react'
import Link from 'next/link'
import Clock from '../assets/clock.svg'
import Decision from '../assets/decision.svg'
import Diary from '../assets/diary.svg'
import Headphone from '../assets/headphone.svg'
import Knowledge from '../assets/knowledge.svg'
import LabTool from '../assets/lab-tool.svg'
import Logo from '../assets/logo-circle.svg'
import Recipe from '../assets/recipe-book.svg'
import Relationship from '../assets/relationship.svg'
import Sadness from '../assets/sadness.svg'
import Sunrise from '../assets/sunrise.svg'
import { Layout } from '../components/Layout'
import { Divider } from '../components/divider/Divider'
import { Image } from '../components/image/Image'
import { md } from '../theme/theme'

const p = css`
  font-size: 1.4rem !important;
  font-weight: 400;
  padding: 10px;
  text-align: left;
  max-width: 600px;
`

const Division = () => (
  <Divider
    height={5}
    className="mt50 mb40"
    css={css`
      max-width: 400px;
    `}
  />
)

export const CTA = () => (
  <div
    className="frc mt10 wrap"
    css={css`
      a {
        width: 210px;
        margin: 10px;
      }
    `}
  >
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://youtu.be/wCoqpIImNdg"
      className="blue normal button"
    >
      Watch 1 minute demo
    </a>
    <a
      href="https://app.cleanslate.sh"
      type="button"
      className="pink semi-bold normal button"
    >
      Get started
    </a>
  </div>
)

const Home = () => {
  return (
    <Layout
      styles={css`
        div,
        p,
        li {
          font-size: 1.3rem;
        }
      `}
    >
      <div className="fcs w90 mw1000 center">
        <div
          className="fcs w100"
          css={css`
            @media (min-width: ${md}px) {
              margin-top: 50px;
            }
          `}
        >
          <h1 className="jumbo mb30">Track food without judgement</h1>
          <p css={p} className="p0">
            Do you struggle with binge eating and self-compassion? Have you
            failed more diets than you can count?
          </p>
          <p css={p} className="p0">
            We have an app just for you! It is free, non-judgemental, and based
            on science.{' '}
            <a
              css={css`
                font-weight: 400;
                text-decoration: none;
                border-bottom: solid 1px #ddd;
                &:hover {
                  border-bottom: solid 1px #111;
                }
              `}
              href="https://github.com/successible/cleanslate"
              target="_"
            >
              You can even host it yourself
            </a>
            .
          </p>
          <p css={p} className="p0 mb20">
            Wonder why we are different? Read the text below or watch the 1
            minute demo. Or create a free account and hop right in!
          </p>
          <CTA />
        </div>

        <Division />

        <div className="module">
          <Image
            src={Logo}
            alt="Sunrise"
            height={90}
            width={90}
            className="center block mt20"
          />

          <h1 className="mt25" id="why-use-clean-slate">
            Why use Clean Slate?
          </h1>
          <p>Glad you asked 😊</p>
          <p>
            We like cover this question in detail. This is because people who
            struggle with binge eating and perfectionism say stuff like:
          </p>

          <Image
            src={Sadness}
            alt="Sadness"
            height={90}
            width={90}
            className="center block mt40"
          />

          <h2 className="mt30" id="but-i-ve-failed-other-apps">
            I&#39;ve failed other apps!
          </h2>
          <p>
            News flash! <strong>That is not your fault</strong>. You were set up
            to fail. That is because most apps will stress three things:
          </p>
          <ul>
            <li>No breaks: You must track every meal on every day.</li>
            <li>Tracking many things: calories, carbs, minerals, etc.</li>
            <li>
              Planning in advance and in detail when and what you&#39;ll eat.
            </li>
          </ul>
          <p>However, again, that is a recipe for failure!</p>
          <Image
            src={Recipe}
            alt="Recipe book"
            height={90}
            width={90}
            className="center block mt40"
          />
          <h2 className="mt20" id="this-is-a-recipe-for-failure">
            Recipe for failure. Really?
          </h2>
          <p>Sadly, yes.</p>
          <p>
            That is because people who struggle with food tend to also struggle
            with perfectionism and self-compassion. This is where any mistake is
            treated as a failure and the diet is abandoned.
          </p>
          <p>
            Unfortunately, the stuff above, like meal planning or tracking
            macros, can play into that. It creates a ton of rules or targets
            that can be broken or failed.
          </p>
          <p>
            This, in turn, leads to distress, abandoned diets, and binging, as
            we cover <Link href="/weight-loss">here</Link>.
          </p>
          <p>
            <strong>And this sucks!</strong>
          </p>
          <p>
            Not just because it is painful. But also because it made you hate
            tracking your food. This is arguably the most effective way to lose
            weight,{' '}
            <a href="/https://pubmed.ncbi.nlm.nih.gov/24636238/">
              according to researchers
            </a>
            .
          </p>
          <Image
            src={LabTool}
            alt="Lab beaker"
            height={90}
            width={90}
            className="center block mt40"
          />
          <h2 id="wait-really" className="mt20">
            Wait, are you serious?
          </h2>
          <p>Yup!</p>
          <p>
            Here is why. If you&#39;ve never tracked calories before, you
            don&#39;t know how many calories are in foods you eat. And this
            would not be a problem, if weight loss{' '}
            <Link href="/weight-loss">was not all about calories</Link>.
          </p>
          <p>
            In fact, trying to lose weight without knowing how many calories
            you&#39;re eating is like trying to play a game with your hands tied
            behind your back. Doomed to fail.
          </p>

          <Image
            src={Diary}
            alt="Diary"
            height={90}
            width={90}
            className="center block mt40"
          />

          <h2 className="mt20">Tracking fixes that!</h2>
          <p>
            That is because tracking teaches you a skill we call{' '}
            <strong>caloric awareness</strong>. This is the ability to know
            roughly how many calories are in the foods you eat.
          </p>
          <p>
            Once you master this skill, you can lose weight{' '}
            <strong>without having to track your food.</strong> In short, you
            can live your life without being chained to a diet or app.
          </p>
          <p>
            That, ultimately, is the goal of Clean Slate. We want you to track
            food without hating the process. Especially if you suffer from
            disordered eating.
          </p>

          <Image
            src={Decision}
            alt="Women holding up two light bulbs"
            height={90}
            width={90}
            className="center block mt40"
          />

          <h2 id="how-we-do-things" className="mt20">
            How we do things
          </h2>
          <p>The Clean Slate philosophy is based on five things:</p>
          <ul>
            <li>We give you a fresh start.</li>
            <li>We ignore the irrelevant.</li>
            <li>We teach you stuff.</li>
            <li>We are really fast!</li>
            <li>We built it for people like us</li>
          </ul>
          <Image
            src={Sunrise}
            alt="Sunrise"
            height={90}
            width={90}
            className="center block mt40"
          />
          <h2 id="a-fresh-start-every-day" className="mt20">
            We give you a fresh start
          </h2>
          <p>In practice, this means two things.</p>
          <ul>
            <li>We reset your logs each day.</li>
            <li>We prevent you from planning anything but today.</li>
          </ul>
          <p>
            Both encourage you to treat every day as a fresh start.{' '}
            <strong>And this matters. A lot.</strong>
          </p>

          <p>
            Weight loss is hard. Stupidly hard. You will have days where you
            just mess up. You could eat an entire pizza or a whole box of Oreos.
            Who knows!
          </p>
          <p>However, as long as you:</p>
          <ul>
            <li>Learn something.</li>
            <li>
              {' '}
              Forgive yourself, a skill called <strong>self compassion</strong>.
            </li>
            <li>And don&#39;t give up.</li>
          </ul>
          <p>
            <strong>It doesn&#39;t matter</strong>.
          </p>
          <p>
            You don&#39;t have be perfect every day. As long as you succeed some
            days, you will lose weight.{' '}
            <strong>It is just how the math plays out</strong>. All diets work{' '}
            if you don{`'`}t give up. So embrace the fresh start! Treat each day
            like another chance to try again!
          </p>

          <Image
            src={Headphone}
            alt="Headphones"
            height={90}
            width={90}
            className="center block mt40"
          />

          <h2 id="we-ignore-irrelevant-stuff" className="mt20">
            We ignore the irrelevant
          </h2>
          <p>
            Every diet, from low-carb to paleo, will lead to the same result if
            you eat the same number of calories.{' '}
            <Link href="/weight-loss">Guaranteed</Link>.
          </p>
          <p>
            This means counting carbs and avoiding food groups is silly. One, it
            is unnecessarily restrictive. Two, it is a distraction from what
            research shows actually matters. This is minimizing hunger through
            caloric density and protein, which we cover{' '}
            <Link href="/weight-loss">here</Link>. Hence, we only track two
            numbers: calories and protein!
          </p>

          <Image
            src={Knowledge}
            alt="Knowledge"
            height={90}
            width={90}
            className="center block mt40"
          />

          <h2 className="mt20" id="we-actually-teach-you-stuff">
            We teach you stuff
          </h2>
          <p>
            Clean Slate comes with a completely free, evidence-based{' '}
            <Link href="/curriculum">curriculum</Link>. It covers useful stuff
            like:
          </p>
          <ul>
            <li>Dealing with hunger and craving.</li>
            <li>How to exercise.</li>
            <li>How to improve your sleep.</li>
            <li>How to eat healthily</li>
            <li>And much, much more!</li>
          </ul>
          <p>
            And this stuff matters. A lot. That is because{' '}
            <strong>weight loss is a skill</strong>. So many people assume they
            can{`'`}t lose weight because they{`'`}ve failed before. But really,
            it{`'`}s because they never learned the skills to succeed.
          </p>
          <p>
            <strong>We want to fix that.</strong>
          </p>
          <p>
            Also, you don{`'`}t need to use the app to get the curriculum. It is
            free online <Link href="/curriculum">here</Link>.
          </p>

          <Image
            src={Clock}
            alt="Clock"
            height={90}
            width={90}
            className="center block mt40"
          />

          <h2 id="we-are-really-really-fast" className="mt20">
            We are really fast!
          </h2>
          <p>
            Clean Slate is faster (and different) due to our philosophy. To lose
            weight:
          </p>
          <ul>
            <li>
              You need to know how many calories you
              {`'`}re eating about <strong>90%</strong> of the time. That means
              eating simple food that is easy to track. We call those{' '}
              <strong>Basic Foods</strong>. For example, one pound of carrots
              will always equal ~ 180 calories. However, one brownie could equal
              200 calories or 700 calories. Who knows! It all depends on how it
              was made.
            </li>
            <li>
              You don{`'`}t need to log the other <strong>10%</strong> of what
              you eat. The occasional brownie at restaurant or birthday party is
              not going to derail your progress. Plus, the point of those events
              is enjoyment! Nothing makes people hate calorie counting more than
              doing it without breaks.
            </li>
          </ul>
          <p>
            Hence, because we omit the food we cannot estimate, like brownies,
            we can log everything else much faster. For example:
          </p>
          <ul>
            <li>Log ingredients via search.</li>
            <li>Log ingredients via barcode scanner.</li>
            <li>
              Log calories and protein directly via <strong>Quick Add</strong>.
            </li>
            <li>Create custom foods and recipes and log those.</li>
          </ul>
          <Image
            src={Relationship}
            alt="Relationship"
            height={90}
            width={90}
            className="center block mt40"
          />

          <h2 id="we-know-what-it-s-like" className="mt20">
            We built it for people like us
          </h2>
          <p>
            Both of us,{' '}
            <a href="https://www.linkedin.com/in/marika-kachman-74b8b8132">
              Mika
            </a>{' '}
            and{' '}
            <a href="https://www.linkedin.com/in/paul-zakin-56aa1b80">Paul</a>,
            have been obese or have suffered from disordered eating, like
            binging. We felt other apps were too complicated and promoted
            perfectionism. We just wanted one that promoted self-compassion and
            education. That would not make you feel terrible for messing up!
          </p>
          <p>
            Also, we love educating people and have done something like this
            before! We founded{' '}
            <a href="https://moneycoach.io/">moneycoach.io</a> to offer free,
            financial education through animated, YouTube videos.
          </p>
          <Image
            src={Logo}
            alt="Sunrise"
            height={90}
            width={90}
            className="center block mt40"
          />

          <h2 className="mt20">You{`'`}ve hit the end</h2>
          <p className="center tcenter">Ready to give Clean Slate a try?</p>
        </div>
        <CTA />
      </div>
    </Layout>
  )
}

export default Home
